import { TypeAnimation } from "react-type-animation"
import { Column } from "../../common/theme/Column"
import { Container } from "../../common/theme/Container"
import { Input } from "../../common/theme/Input"
import { Checkbox } from "../../common/theme/Input/Checkbox"
import { Row } from "../../common/theme/Row"
import { useSelector, useDispatch } from "react-redux"
import { updateData } from "../../Actions/dataActions"
import emailjs from '@emailjs/browser'
import { LoadingOutlined } from '@ant-design/icons'

import { useNavigate } from "react-router"



import './style.sass'

import { useEffect, useRef, useState } from "react"

export const Application = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ref = useRef(null)


    const [loanAmount, setLoanAmount] = useState(useSelector(state => state.data.loanAmount))
    const [loanTerm, setLoanTerm] = useState(72)
    const [isAmountSet, setAmount] = useState(false)
    const [applicationSent, setApplicationSent] = useState(false)
    const [isMessageSending, SetMessageSending] = useState(false)
    const [mounted, setMounted] = useState(false)

    const loanOption = useSelector(state => state.data.loanOption)
    const ratenabsicherung = useSelector(state => state.data.ratenabsicherung)
    const kinder = useSelector(state => state.data.kinder)

    const confirmation1 = useSelector(state => state.data.confirmation1) || false
    const confirmation2 = useSelector(state => state.data.confirmation2) || false


    const handleAmountOnBlur = (event) => {
        let amount = event.target.value.toString().replace(`'`, '').replace(`'`, '')
        if (parseInt(amount) < 5000 || !parseInt(amount)) {
            setLoanAmount(5000)
            dispatch(updateData(5000))
        }
        else if (parseInt(amount) > 300000) {
            setLoanAmount(300000)
            dispatch(updateData(300000))
        } else {
            dispatch(updateData({ loanAmount }))
        }
    }

    const handleAmountFocus = event => {
        setLoanAmount('')
    }


    useEffect(() => {
        if (!mounted) {
            setMounted(true)
            dispatch(updateData({}))
            dispatch(updateData({ loanAmount }))
            dispatch(updateData({ loanTerm }))
        }
    })

    const handleFormSubmit = event => {
        event.preventDefault()





        if (confirmation1 && confirmation2) {
            SetMessageSending(true)
            setApplicationSent(true)
            emailjs.sendForm('service_op0lmv6', 'template_n1rbpe8', ref.current, 'gJ37SCMu1OSRRygMR')
                .then((result) => {
                    console.log(result.text);
                    
                    SetMessageSending(false)
                }, (error) => {
                    console.log(error.text);
                });
        }

    }

    const hiddenInputs = (
        <>
            <input name='loanAmount' value={useSelector(state => state.data['loanAmount']) || ''} readOnly style={{ display: 'none' }} />
            <input name='loanTerm' value={useSelector(state => state.data['loanTerm']) || ''} readOnly style={{ display: 'none' }} />
            <input name='ratenabsicherung' value={useSelector(state => state.data['ratenabsicherung']) || ''} readOnly style={{ display: 'none' }} />
            <input name='arbeitsverhaltniss' value={useSelector(state => state.data['arbeitsverhaltniss']) || ''} readOnly style={{ display: 'none' }} />
            <input name='offeneFinanzierrungen' value={useSelector(state => state.data['offeneFinanzierrungen']) || ''} readOnly style={{ display: 'none' }} />
            <input name='betreibung' value={useSelector(state => state.data['betreibung']) || ''} readOnly style={{ display: 'none' }} />
            <input name='kinder' value={useSelector(state => state.data['kinder']) || ''} readOnly style={{ display: 'none' }} />
            <input name='0-5-Jahre' value={useSelector(state => state.data['0-5-Jahre']) || ''} readOnly style={{ display: 'none' }} />
            <input name='6-9-Jahre' value={useSelector(state => state.data['6-9-Jahre']) || ''} readOnly style={{ display: 'none' }} />
            <input name='10-11-Jahre' value={useSelector(state => state.data['10-11-Jahre']) || ''} readOnly style={{ display: 'none' }} />
            <input name='>11-Jahre' value={useSelector(state => state.data['ratenabsicherung']) || ''} readOnly style={{ display: 'none' }} />
            <input name='nachricht' value={useSelector(state => state.data['nachricht']) || ''} readOnly style={{ display: 'none' }} />
            <input name='confirmation1' value={useSelector(state => state.data['confirmation1']) || ''} readOnly style={{ display: 'none' }} />
            <input name='confirmation2' value={useSelector(state => state.data['confirmation2']) || ''} readOnly style={{ display: 'none' }} />
        </>
    )


    return (
        <>

            <div className="application">
                <Container className='xs-p-10 sm-p-0'>

                    <div className="xs-p-0-t">
                      

                        {
                            applicationSent &&
                            <Column className="align-items-center justify-center  full-width" style={{ height: '100vh' }}>

                                {
                                    
                                        isMessageSending && <LoadingOutlined style={{ fontSize: '82px' }} />
                                    
                                    
                                }
                                {
                                    !isMessageSending &&
                                    <>
                                        <h4 className="font-lg">Vielen Dank!</h4>
                                        <span className="xs-m-50-b">Wir melden uns innerhalb 24h</span>
                                        <button
                                            className='xs-m-25-t font-sm font-white bg-dark-gray no-border
                                            rounded-3 bold-2'
                                            onClick={async () => {
                                                await navigate('/')
                                                document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                            }}
                                        >Zurück zur Startseite</button>
                                    </>
                                }


                            </Column>
                        }

                        {
                            !applicationSent && !isMessageSending &&
                            <>
                                <div>
                                    <h3 className="font-green font-lg xs-m-100-t sm-m-200-t"><TypeAnimation
                                        sequence={[
                                            'Ihr Privatkredit',
                                            1000,
                                        ]}
                                        speed={10}
                                        repeat={Infinity}
                                        cursor={true}
                                    /></h3>


                                    <p className="xs-m-25-t sm-m-50-t">
                                        Beantragen Sie hier Ihren gewünschten Privatkredit. Nach Prüfung Ihrer Kreditfähigkeit erhalten Sie online einen vorläufigen Kreditentscheid.
                                    </p>
                                    <br />
                                    <p>
                                        Die Kreditvergabe ist verboten, wenn sie zur Überschuldung führt (Art. 3 UWG). Voraussetzung für die Kreditvergabe ist in jedem Falle eine erfolgreiche Kreditfähigkeitsprüfung.
                                    </p>
                                    <br />
                                    <strong>Bitte wahlen Sie:</strong>

                                    <Input className='xs-m-10-t' type='radio' initial={loanOption} name='loanOption' options={[
                                        {
                                            name: 'Ich habe einen konkreten Kreditwunsch.',
                                            id: 0
                                        },
                                        {
                                            name: 'Ich möchte meinen maximalen Kreditbetrag erfahren.',
                                            id: 1
                                        },
                                    ]} />

                                    {
                                        loanOption == 0 && <div className="xs-m-25-t">
                                            <label className="bold-3">Gewünschter Kreditbetrag</label>
                                            <div className="static-row xs-m-25-b xs-m-10-t" >
                                                <div className="data-input full-width" style={{ width: '200%' }}>
                                                    <input
                                                        type='range'
                                                        className="full-width xs-m-10-t"
                                                        min={5000}
                                                        max={300000}
                                                        step={1000}
                                                        value={loanAmount}
                                                        onChange={event => {
                                                            setLoanAmount(event.target.value)
                                                            dispatch(updateData({ loanAmount: event.target.value }))
                                                        }}
                                                    />
                                                </div>

                                                <input
                                                    className="amount-input static-row justify-center border rounded-3 xs-p-10 xs-m-25-l sm-m-50-l" style={{ width: '100%', boxSizing: 'border-box' }}

                                                    type={'text'}
                                                    value={loanAmount.toString().replace("'", '').replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                                                    onFocus={handleAmountFocus}
                                                    onChange={event => {
                                                        if (event.target.value.length < 9)
                                                            setLoanAmount(event.target.value)
                                                    }}
                                                    inputMode='numeric' // Ensures numeric keyboard on mobile              
                                                    onBlur={handleAmountOnBlur}
                                                />





                                            </div>
                                            <label className="bold-3">Laufzeit</label>
                                            <div className="static-row xs-m-10-t">
                                                <div className="data-input full-width" style={{ width: '200%' }}>
                                                    <input
                                                        type='range'
                                                        className="full-width xs-m-10-t"
                                                        min={6}
                                                        max={84}
                                                        step={6}
                                                        value={loanTerm}
                                                        onChange={event => {
                                                            setLoanTerm(event.target.value)
                                                            dispatch(updateData({ loanTerm: event.target.value }))
                                                        }}
                                                    />
                                                </div>
                                                <div className="static-row justify-center rounded-3 xs-p-10 xs-m-25-l sm-m-50-l" style={{ width: '100%', boxSizing: 'border-box' }}>
                                                    <strong>{loanTerm} Monate</strong>
                                                </div>
                                            </div>

                                            <div className="static-row xs-m-50-t">
                                                <label className="xs-m-25-r  sm-m-50-r bold-3">Ratenabsicherung</label>
                                                <Input type='slider' name={'ratenabsicherung'} initial={ratenabsicherung} />

                                            </div>


                                        </div>


                                    }

                                    <button
                                        onClick={async () => {
                                            await setAmount(true)
                                            document.getElementById('application-form').scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
                                        }}
                                        className="full-width font-sm no-border bold-2 bg-green xs-m-50-t sm-m-100-t font-white rounded-3">Weiter
                                    </button>
                                    {
                                        !isAmountSet && <div className='static-row text-center xs-m-50-t xs-m-50-b'>
                                            <p>
                                                Kreditdarstellung: Kredit von CHF 10'000. Effektive Jahreszinsen zwischen 4,5 % und 10,95 % über einen Zeitraum von 12 Monaten führen zu Gesamtzinsen zwischen CHF 318 und CHF 575.
                                                Laufzeit: 6-120 Monate; Maximaler jährlicher Zinssatz (einschließlich aller Kreditbearbeitungskosten) 10,95 %. Kreditgenehmigungen sind verboten, wenn sie zu einer Überschuldung
                                                des Verbrauchers führen. (Art. 3 LCD)
                                            </p>
                                        </div>
                                    }

                                </div>
                            </>
                        }




                    </div>
                </Container>
            </div>
            {
                !applicationSent && isAmountSet && !isMessageSending && <div className="content-block" id="application-form">
                    <Container className='data-column'>
                        <h2 className=" xs-m-50-b">Angaben zu Ihrem Kreditantrag</h2>

                        <form onSubmit={event => handleFormSubmit(event)} ref={ref}>
                            {hiddenInputs}
                            <Column >
                                <Row>
                                    <Input name='vorname' type='text' label='Vorname' required={true} />
                                    <div style={{ width: '20px' }} />
                                    <Input name='nachname' type='text' label='Nachname' required={true} />
                                </Row>
                                <Row>
                                    <Input name='geburtstag' type='date' label='Geburtstag' required={true} />
                                    <div style={{ width: '20px' }} />
                                    <Input required={true} name='zivilstand' type='select' label='Zivilstand' options={[
                                        'Ledig',
                                        'Verhairatet',
                                        'Geschieden',
                                        'Getrennt',
                                        'Verwitwet',
                                        'Eingetragene Partnerschaft',
                                        'Gerichtlich aufgelöste Partnerschaft',
                                    ]} />
                                </Row>

                                <Row>
                                    <Input name='telefonnummer' type='tel' label='Telefonnummer' required={true} />
                                    <div style={{ width: '20px' }} />
                                    <Input name='email' type='email' label='E-Mail' required={true} />
                                </Row>



                                <Input type='options' name={'arbeitsverhaltniss'} label='Arbeitsverhältniss' options={[
                                    {
                                        value: 'Angestellt',
                                        id: 0,
                                    },
                                    {
                                        value: 'Selbstständig erwerbstätig',
                                        id: 1,
                                    },
                                    {
                                        value: 'AHV/ IV/ EL - Rente',
                                        id: 2,
                                    },
                                    {
                                        value: ''
                                    }
                                ]} />
                                <Input type='options' name='offeneFinanzierrungen' label='Offene Finanzierrungen' options={[
                                    {
                                        value: 'Kredit',
                                        id: 0,
                                    },
                                    {
                                        value: 'Leasing',
                                        id: 1,
                                    },
                                    {
                                        value: 'Hypotheke',
                                        id: 2,
                                    },
                                    {
                                        value: 'Bestehender kredit',
                                        id: 3
                                    }
                                ]} />
                                <Input type='options' name='betreibung' label='Betreibung' options={[
                                    {
                                        value: 'Ja',
                                        id: 0,
                                    },
                                    {
                                        value: 'Nein',
                                        id: 1,
                                    },
                                    {
                                        value: 'Abbezahlt',
                                        id: 2,
                                    },
                                    {
                                        value: '',
                                    }
                                ]} />
                                <Input type='options' name='kinder' label='Unterhaltspflichtige Kinder im gleichen Haushalt' options={[
                                    {
                                        value: 'Keine Kinder im gleichen Haushalt',
                                        id: 0,
                                    },
                                    {
                                        value: 'Alleinerziehend im gleichen Haushalt',
                                        id: 1,
                                    },
                                    {
                                        value: 'Gemeinsam erziehend im gleichen Haushalt',
                                        id: 2,
                                    },
                                    {
                                        value: ''
                                    }
                                ]} />
                                {
                                    kinder && kinder !== 'Keine Kinder im gleichen Haushalt' && <div className="data-input xs-m-25-t">
                                        <label>Anzahl unterhaltspflichtige Kinder im gleichen Haushalt lebend</label>
                                        <Input type='number' name={'0-5-Jahre'} label='0-5 Jahre' required={false} />
                                        <Input type='number' name={'6-9-Jahre'} label='6-9 Jahre' required={false} />
                                        <Input type='number' name={'10-11-Jahre'} label='10-11 Jahre' require={false} />
                                        <Input type='number' name={'>11-Jahre'} label='>11 Jahre' required={false} />
                                    </div>
                                }

                                <Input type='textarea' name='nachricht' label='Nachricht' className='xs-m-50-t' height={70} />


                                <Row className="xs-m-50-t sm-m-100-t">
                                    <div className="static-row full-width">
                                        <Checkbox name='confirmation1' />
                                        <div className="xs-m-25-l" style={{ width: '70%' }}>
                                            <p>
                                                Hiermit bestätige ich, dass ich die rechtlichen  <a target="_blank" href="/datenschutz">Hinweise vollständig</a><br/>
                                                gelesen habe und in allen Punkten mit ihnen einverstanden bin.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="static-row full-width xs-m-50-t sm-m-0-t">
                                        <Checkbox name='confirmation2' />
                                        <div className="xs-m-25-l" style={{ width: '70%' }}>
                                            <p>
                                                Ich bin damit einverstanden, dass Fast & Fair Credit meinen
                                                Kreditantrag bei Banken in der Schweiz bearbeitet und mir ein
                                                unverbindliches Angebot sendet.
                                            </p>
                                        </div>
                                    </div>

                                </Row>

                                <button
                                    className='xs-m-50-t sm-m-100-t no-border  font-sm font-white rounded-3 bg-green'
                                    type="submit"
                                >
                                    Absenden
                                </button>


                                <div className='static-row text-center xs-m-100-t'>
                                    <p>
                                        Kreditdarstellung: Kredit von CHF 10'000. Effektive Jahreszinsen zwischen 4,5 % und 10,95 % über einen Zeitraum von 12 Monaten führen zu Gesamtzinsen zwischen CHF 318 und CHF 575.
                                        Laufzeit: 6-120 Monate; Maximaler jährlicher Zinssatz (einschließlich aller Kreditbearbeitungskosten) 10,95 %. Kreditgenehmigungen sind verboten, wenn sie zu einer Überschuldung
                                        des Verbrauchers führen. (Art. 3 LCD)
                                    </p>
                                </div>

                            </Column>
                        </form>
                    </Container>
                </div>
            }
        </>

    )
}