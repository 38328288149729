import { useState } from "react"
import { Column } from "../../common/theme/Column"
import { Container } from "../../common/theme/Container"
import { Row } from "../../common/theme/Row"

import Facebook from '../../common/img/facebook.svg'
import Instagram from '../../common/img/insta.svg'

import Logo from '../../common/img/ff_logo.svg'
import { useNavigate } from "react-router"

export const Impressum = (props) => {

    return (
        <Container className='content-block'>
            <h3 className='xs-m-50-t sm-m-0-t'>Impressum</h3>
            <p className="xs-m-50-t font-sm">
                Fast & Fair Credit, Inhaber Migliore <br />
                Enschenweg 4, 6340 Baar
            </p>

            <p className="xs-m-25-t font-sm">
                Handelsregister-Nummer CH-170.1011795-3 <br />
                UID CHE-233.681.927
            </p>
        </Container>
    )
}
