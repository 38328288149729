import { Container } from '../../common/theme/Container';
import { Row } from '../../common/theme/Row/';
import { Column } from '../../common/theme/Column/';
import Logo from '../../common/img/ff_logo.svg'
import ReactFlipCard from 'reactjs-flip-card'
import { useInView } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import './style.sass'
import { useRef } from 'react';

export const About = () => {
    const ref = useRef(null)
    const isInView = useInView(ref)


    return (
        <div id='about' className='content-block bg-gray relative' style={{ overflow: 'hidden' }}>

            <Container className='relative'>
                <motion.img

                    className='bg-logo' src={Logo} alt='Fast & Fair Credit Logo'
                />
                <h3 className='font-dark-gray'>                  
                    Über uns
                </h3>

                <Column className='about-content xs-m-50-t font-sm'>

                    <p>
                        Herzlich willkommen bei Fast & Fair Credit – Ihrer Quelle für 
                        blitzschnelle und moderne Kreditvermittlung. 
                        <br/><br/>
                        Wir sind mehr als nur ein Kreditvermittlungsunternehmen; wir sind Ihre Partner, die mit beispielloser Geschwindigkeit und modernster Technologie dafür sorgen, dass Ihr Weg zu finanziellen Lösungen so unkompliziert wie möglich wird. Bei uns geht es nicht nur um Zahlen, sondern um Ihre Träume und Ziele. Erleben Sie die Aufregung, wenn wir in Windeseile maßgeschneiderte Finanzierungsoptionen für Sie finden. <br/><br/>Vertrauen Sie Fast & Fair Credit, um mit Leidenschaft und Engagement Ihre finanziellen Träume Wirklichkeit werden zu lassen.
                    </p>                   

                </Column>



            </Container>
        </div>

    )
}
