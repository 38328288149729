import { Column } from "../Column"
import { Options } from "./Options"
import { Radio } from "./Radio"
import { Slider } from "./Slider"
import { useDispatch } from "react-redux"
import { updateData } from "../../../Actions/dataActions"

export const Input = props => {
    const dispatch = useDispatch()

    switch (props.type) {
        case 'select':
            return (
                <Column className='data-input full-width'>
                    <label
                        className="font-xs bold-2"
                        htmlFor={props.name}

                    >
                        {props.label}
                    </label>
                    <select
                        className="xs-m-25-t xs-m-25-b font-xs"                        
                        id={props.name}
                        name={props.name}
                        onChange={(event) => dispatch(updateData({ [props.name]: event.target.value }))}
                        required={props.required}
                    >
                        <option style={{display: 'none'}} defaultValue={''} />
                        { props.options.map(option => <option key={option} value={option}>{option}</option>) }
                    </select>
                </Column>
            )

        case 'textarea':
            return (
                <div className={`data-input full-width ${props.className}`}>
                    <Column>
                        <label>{props.label}</label>
                        <textarea style={{ height: props.height }} required={props.required} className="xs-m-10-t font-xs"
                            onChange={(event) => dispatch(updateData({ [props.name]: event.target.value }))} />
                    </Column>



                </div>
            )

        case 'range':
            return (

                <div className="data-input full-width">

                    <input type='range' required={props.required} className="full-width xs-m-10-t" />
                    
                </div>

            )

        case 'slider':
            return (
                <Slider name={props.name} initial={props.initial} />
            )

        case 'options':
            return (
                <Options options={props.options} label={props.label} name={props.name} />


            )
        case 'radio':
            return (
                <Column className={props.className}>
                    <Radio options={props.options} initial={props.initial} />

                </Column>

            )
        case 'number':
            return (
                <div className="data-input static-row xs-p-25-t">
                    <input
                        type='number'
                        className="xs-m-10-r"
                        style={{ width: '30px' }}
                        placeholder="0"
                        onChange={(event) => dispatch(updateData({ [props.name]: event.target.value }))}
                        required={props.required}
                    />
                    <label>{props.label}</label>
                </div>
            )

        default:
            return (
                <>
                    <Column className='data-input full-width'>
                        <label
                            className="font-xs bold-2"
                            htmlFor={props.name}

                        >
                            {props.label}
                        </label>
                        <input
                            className="xs-m-25-t xs-m-25-b font-xs"
                            type={props.type}
                            id={props.name}
                            name={props.name}
                            onChange={(event) => dispatch(updateData({ [props.name]: event.target.value }))}
                            required={props.required}
                        />
                    </Column>
                </>
            )
    }
}