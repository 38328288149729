import { useState, useEffect, useRef } from "react"
import { Container } from "../../common/theme/Container"
import Menu from '../../common/img/menu.svg'
import Logo from '../../common/img/ff_logo.svg'
import { Link } from "react-router-dom"

import './style.sass'
import { useNavigate } from "react-router"

export const Navigation = props => {
    const path = window.location.pathname
    const [navbar, setNavbar] = useState(path != '/')
    const [menu, setMenu] = useState(false)

    const navigate = useNavigate()

    const scrollTo = (elementId, position) => {


        if (elementId)
            document.getElementById(elementId).scrollIntoView({ behavior: 'smooth', block: position, inline: 'nearest' })
        else
            document.body.scrollIntoView({ behavior: 'smooth', blcok: 'position', inline: 'nearest' })


    }

    const switchNavbar = () => {

            
            if (path == '/')
                setNavbar(window.scrollY != 0 && !menu)                
            else
                setNavbar(true)
    
    }

    useEffect(() => {        
            window.addEventListener("scroll", switchNavbar);
    }, [])

    return (
        <>
            <nav className={`mobile navbar nav-main-active`}>
                <div className="static-row justify-space-between ">
                    <div className="static-row pointer mobile-logo" onClick={ async () => {
                        await navigate('/')
                        
                        
                        scrollTo('', 'start')
                    }}>
                        <img src={Logo} alt='Fast & Fair Credit Logo' />
                        <h2 className="font-md bold-3">Fast & Fair <br /> <span className={'font-green'}>Credit</span></h2>
                    </div>

                    <div className="menu-button-container">
                        <button
                            className='menu-button'
                            onClick={() => {
                                setMenu(!menu)

                            }}

                        >
                            <img src={Menu} alt='menu' />
                        </button>
                    </div>

                </div>


            </nav>
            
            {
                menu &&
                <div className="mobile-overlay" onClick={() => setMenu(false)}>

                </div>
            }


            <div className={`mobile mobile-menu-container ${menu && 'mobile-menu-active'}`}>
                <ul>
                    <li>
                        <button
                            onClick={() => {
                                navigate('/kreditantrag')
                                scrollTo('', 'start')
                                setMenu(false)
                            }}
                            className={path == '/kreditantrag' ? "font-green font-sm rounded-3 bold-1 border no-bg" : "font-white font-md bg-green rounded-3 bold-2"}>Kreditantrag</button>
                    </li>
                    <li className="pointer" onClick={async () => {
                        await navigate('/')
                        scrollTo('about', 'start')
                        setMenu (false)
                    }}>
                        Über Uns
                    </li>
                    <li className="pointer" onClick={async () => {
                        await navigate('/')
                        scrollTo('contact', 'center')
                        setMenu(false)
                    }}>
                        Kontakt
                    </li>
                    <li lassName="pointer" onClick={async () => {
                        await navigate('/faq')
                        scrollTo('', 'start')
                        //scrollTo('faq', 'center')
                        setMenu(false)
                    }}>
                        FAQ
                    </li>

                </ul>
            </div>

            <nav className={`desktop nav-main ${navbar && 'nav-main-active'} `}>
                <Container >
                    <div className={`static-row justify-space-between relative`}>
                        <div
                            onClick={() => {
                                navigate('/')
                                scrollTo('', 'start')
                            }}
                            className="static-row nav-logo relative pointer"
                        >
                            <img className="xs-m-25-r" src={Logo} alt='Fast & Fair Credit Logo' />
                            <hr className="xs-m-25-r" />
                            <h2 className="font-lg bold-3">Fast & Fair <span className={navbar || path == '/kreditantrag' ? 'font-green' : 'font-white'}>Credit</span></h2>
                        </div>
                        <div className={`nav-menu-container ${navbar && 'nav-menu-active'}`} />
                        <div className={`static-row nav-menu rounded-3 font-xs `}>


                            <span className="pointer" onClick={async () => {
                                await navigate('/')
                                scrollTo('about', 'start')
                            }}
                            >
                                Über Uns
                            </span>

                            <span className="pointer" onClick={async () => {
                                await navigate('/faq')
                                scrollTo('', 'start')
                            }}
                            >
                                FAQ
                            </span>

                            <span className="pointer" onClick={async () => {
                                await navigate('/')
                                scrollTo('contact', 'center')
                            }}
                            >
                                Kontakt
                            </span>
                            <button
                                onClick={() => {
                                    navigate('/kreditantrag')
                                    scrollTo('', 'start')
                                }}
                                className={path == '/kreditantrag' ? "font-green font-sm rounded-3 bold-1 border no-bg" : "font-white font-md bg-green rounded-3 bold-2"}>Kreditantrag</button>
                        </div>


                    </div>
                </Container>
            </nav>
        </>
    )
}