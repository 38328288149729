import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { reducers } from './Reducers/';
import React from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM from 'react-dom'

import './common/theme/index.sass';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';

const store = createStore(
  reducers, 
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>  
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>    
    </React.StrictMode>
  </Provider>
);

reportWebVitals();