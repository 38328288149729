export const updateData = data => {
    return {
        type: 'UPDATE_DATA',
        payload: data
    }    
}

export const setDataValid = value => {
    return {
        type: 'SET_DATA_VALID',
        payload: value
    }
}