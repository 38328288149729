import { useState } from "react"
import { Column } from "../../common/theme/Column"
import { Container } from "../../common/theme/Container"
import { Row } from "../../common/theme/Row"

import Facebook from '../../common/img/facebook.svg'
import Instagram from '../../common/img/insta.svg'

import Logo from '../../common/img/ff_logo.svg'
import { useNavigate } from "react-router"

export const Datenschutz = (props) => {

    return (
        <Container className='content-block'>
            <h3 className='relative xs-m-50-t sm-m-0-t'>Datenschutz</h3>
            <h4>Nutzungsbedingungen und rechtliche Informationen</h4>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Disclaimer für Online-Kreditanträge</h4>
            <p>
                Ich nehme zur Kenntnis, dass Credisa (nachfolgend: Vermittler) meinen Antrag zur Prüfung an die 
                Bank-Now AG, Cembra Money Bank AG und an die Corner Bank SA (je einzeln nachfolgend das „Finanzierungsinstitut“) 
                über das Internet, via Email oder per Fax/Post weiterleitet. Zudem nehme ich zur Kenntnis bzw. bestätige was folgt: 
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Antragsprüfung und Kreditauskunftsdaten:</h4>
            <p>
                Ich bestätige die Vollständigkeit, Aktualität und Richtigkeit aller meiner im Antrag gemachten Angaben und 
                ermächtige das Finanzierungsinstitut, im Zusammenhang mit der Prüfung dieses Antrags und der Abwicklung der 
                vertraglichen Beziehung Auskünfte über meine Person bei Dritten, insbesondere Banken, der Zentralstelle für 
                Kreditinformation (ZEK), Behörden (z.B. Betreibungs-, Grundbuch- und Steuerämtern, Einwohnerkontrollen, Kindes- 
                und Erwachsenenschutzbehörden), Wirtschaftsauskunfteien, Kreditvermittlern, Arbeitgebern, Konzerngesellschaften
                 des Finanzierungsinstituts und gegebenenfalls der Informationsstelle für Konsumkredit (IKO) einzuholen. Zu 
                 den vorgenannten Zwecken entbinde ich diese Stellen vom Bankkunden-, Post-, Amts- beziehungsweise Geschäftsgeheimnis. 
                Ich nehme zur Kenntnis, dass das Finanzinstitut gegebenenfalls verpflichtet ist, ein Konsumentenleasing / einen zu 
                gewährenden bzw. gewährten Konsumkredit der Informationsstelle für Konsumkredit (IKO) zu melden. Ich ermächtige das 
                Finanzierungsinstitut ausserdem, das beantragte Geschäft der ZEK mitzuteilen sowie bei entsprechenden gesetzlichen 
                Pflichten auch anderen Stellen Meldung zu erstatten. Gemeldet werden dabei namentlich Kredit- bzw. Finanzierungsart, 
                -höhe und -modalitäten sowie die Personalien des/der Antragstellenden und allenfalls qualifizierte Zahlungsrückstände 
                oder Missbräuche. Ich anerkenne das Recht der ZEK, ihren Mitgliedern über die gemeldeten Daten Auskünfte zu erteilen. 
                Das Finanzierungsinstitut kann den Antrag ohne Angabe von Gründen ablehnen. 
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Zusammenarbeit mit Vermittlern:</h4>
            <p>
                Ich nehme zur Kenntnis, dass das Finanzierungsinstitut bei Anträgen, die es von einem Vermittler erhält, mit diesem die 
                notwendigen Informationen im Zusammenhang mit Antrags- /Kreditfähigkeitsprüfung sowie Vertragsabschluss und -abwicklung 
                (z.B. Parameter und Ergebnis der Antragsprüfung, Zustellung der Vertragsdokumente, Informationen zum Vertragsstatus und 
                Zahlungsverhalten sowie Saldo) austauschen kann und entbinde das Finanzierungsinstitut in diesem Umfang sofern und soweit 
                erforderlich vom Bankkundengeheimnis. 
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Angaben zu Partnern:</h4>
            <p>
                Falls ich im Antrag Angaben zu meinem/r Ehepartner/-in bzw. eingetragenem/n Partner/-in („Partner“) gemacht habe, bestätige 
                ich, dass (i) ich meinen Partner über diesen Antrag informiert habe, (ii) das Finanzierungsinstitut die obenstehenden Angaben 
                mittels direkter Rückfragen bei meinem Partner überprüfen darf, und (iii) mein Partner damit einverstanden ist, dass das 
                Finanzierungsinstitut ihn betreffende Auskünfte wie oben beschrieben (einschliesslich Durchführung von Informativanfragen 
                bei der ZEK) einholen darf.   
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Datenbearbeitung:</h4>
            <p>
                Ich nehme zur Kenntnis, dass das Finanzierungsinstitut meine Daten zur Prüfung meines Antrags sowie zu Risikobewertungs- und 
                Marketingzwecken (Datenauswertung und Profilerstellung) bearbeiten kann. Zudem können mir andere Produkte und Dienstleistungen, 
                auch von Konzerngesellschaften des Finanzierungsinstitutes sowie weiteren Dritten, per Post oder Telefon angeboten werden. Ich 
                kann dieser Datenbearbeitung zu Marketingzwecken jederzeit widersprechen. <b>Mit Angabe meiner E-Mail Adresse erkläre ich mich zudem 
                einverstanden, dass das Finanzierungsinstitut mir die vorgenannten Angebote auch per E-Mail zustellen kann.</b> Ich akzeptiere im 
                Zusammenhang mit der offenen Kommunikation via E-Mail ausdrücklich das Risiko, dass meine Daten bei der Übermittlung durch Dritte 
                abgefangen oder veröffentlicht werden können, oder dass unbefugte Dritte Rückschluss auf eine Vertragsbeziehung zwischen dem 
                Finanzierungsinstitut und mir ziehen können, und dass insoweit der Geheimnisschutz bzw. das Bankkundengeheimnis nicht gewährleistet 
                ist. Meine Einwilligung in die elektronische Zustellung von Werbesendungen kann ich jederzeit widerrufen. 
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Zusammenarbeit mit Dienstleistern (Outsourcing): </h4>
            <p>
                Ich nehme zur Kenntnis, dass das Finanzierungsinstitut Dienstleistungen, insbesondere im Bereich der Abwicklung von Geschäftsprozessen, 
                IT-Sicherheit und Systemsteuerung, Marktforschung und -bearbeitung, der Berechnung von geschäftsrelevanten Kredit- und Marktrisiken 
                sowie der Administration von Vertragsverhältnissen (z.B. Antrags- und Vertragsabwicklung, Inkasso, Kommunikation mit Kunden) an Dritte 
                („Dienstleister“) auslagern kann. Das Finanzierungsinstitut stellt diesen Dienstleistern die zur Erfüllung der vertraglichen Pflichten 
                notwendigen Daten zur Verfügung und kann diese Daten dafür auch ins Ausland weiterleiten. Dienstleister, deren Mitarbeiter sowie 
                Subunternehmer werden dabei vom Finanzierungsinstitut vertraglich zur Vertraulichkeit verpflichtet. Die Dienstleister befinden sich in 
                der Regel in der Schweiz oder im EU-/EWR-Raum, können aber grundsätzlich auf der ganzen Welt niedergelassen sein. Falls Personendaten in 
                einen Staat übermittelt werden, der aus Sicht der Schweiz über kein angemessenes Datenschutzniveau verfügt, stellt das Finanzierungsinstitut 
                durch geeigneten Garantien, wie z.B. die Vereinbarung der Standardvertragsklauseln einen angemessenen Datenschutz sicher.
            </p>
            <p>
                Ich nehme zur Kenntnis, dass die Kommunikation zwischen dem Finanzierungsinstitut und mir bzw. dem Vermittler über elektronische Kanäle erfolgen 
                kann. Der Vermittler sowie das Finanzierungsinstitut können die Vertraulichkeit von Daten bei der Übertragung über das Internet nicht gewährleisten. 
                Rückschlüsse Dritter auf eine zwischen mir und dem Finanzierungsinstitut bestehende bzw. bevorstehende Geschäftsbeziehung (allenfalls Bankbeziehung) 
                sind daher möglich. 
            </p>
            <p>
                Weitere Informationen zur Bearbeitung von Personendaten durch das Finanzierungsinstitut lassen sich der Datenschutzerklärung auf der Webseite des 
                Finanzierungsinstitut entnehmen bzw. können beim Finanzierungsinstitut angefragt werden.
            </p>
            <h4 className="font-md xs-m-50-t xs-m-25-b">Die Kreditvergabe ist verboten, falls sie zur Überschuldung führt (Art. 3 UWG). </h4>
        </Container>
    )
}
