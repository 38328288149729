import { Container } from '../../common/theme/Container';
import { Row } from '../../common/theme/Row/';
import { Column } from '../../common/theme/Column/';
import { Card } from './Card';
import { useInView } from 'framer-motion';
import { useRef } from 'react';
import { TypeAnimation } from 'react-type-animation';

import Plus from '../../common/img/plus.svg'
import Immobilien from '../../common/img/immobilien.svg'
import Bildung from '../../common/img/bildung.svg'
import Elektronik from '../../common/img/elektronik.svg'
import Business from '../../common/img/business.svg'
import Reisen from '../../common/img/reisen.svg'
import Fahrzeuge from '../../common/img/fahrzeuge.svg'
import InteriorDesign from '../../common/img/interior.svg'

import './style.sass'
import { Bubble } from '../bubble';
import { ParticlesComponent } from './ParticlesComponent';

import { useNavigate } from "react-router"

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';

// import required modules
import { EffectCreative, Pagination, Autoplay } from 'swiper/modules';

export const LoanList = () => {
    const ref = useRef(null)
    const isInView = useInView(ref)
    const navigate = useNavigate()

    const scrollTo = (elementId, position) => {


        if (elementId)
            document.getElementById(elementId).scrollIntoView({ behavior: 'smooth', block: position, inline: 'nearest' })
        else
            document.body.scrollIntoView({ behavior: 'smooth', blcok: 'position', inline: 'nearest' })


    }

    const goTo = async (path) => {

        await navigate(path)

        scrollTo('', 'start')



    }



    return (
        <>

            <div className='loanList font-white relative' style={{ overflow: 'hidden' }}>
                <ParticlesComponent />


                <Container>


                    <h3 className='xs-m-100-t sm-m-150-t font-white title' style={{ width: '30px', height: '40px' }}>
                        Privatkredit für
                    </h3>

                    <div className='mobile'>
                        <Swiper

                            grabCursor={true}
                            effect={'creative'}


                            slidesPerView={1}
                            pagination={true}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                                pauseOnMouseEnter: false,

                            }}
                            creativeEffect={{
                                prev: {
                                    shadow: true,
                                    translate: [0, 0, -400],
                                },
                                next: {
                                    translate: ['100%', 0, 0],
                                },
                            }}
                            modules={[EffectCreative, Pagination, Autoplay]}
                            className="mySwiper"
                        >
                            <SwiperSlide

                            >
                                <a href='/kredit/business'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Business} alt='business' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Business</strong>
                                </a>
                            </SwiperSlide>
                            <SwiperSlide>
                                <a href='/kredit/immobilien'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Immobilien} alt='immobilien' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Immobilien</strong>
                                </a>

                            </SwiperSlide>
                            <SwiperSlide>
                                <a href='/kredit/reisen'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Reisen} alt='Reisen' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Reisen</strong>
                                </a>

                            </SwiperSlide>
                            <SwiperSlide>
                                <a href='/kredit/bildung'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Bildung} alt='Bildung' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Bildung</strong>
                                </a>

                            </SwiperSlide>
                            <SwiperSlide>
                                <a href='/kredit/fahrzeuge'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Fahrzeuge} alt='Fahzeuge' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Fahzeuge</strong>
                                </a>

                            </SwiperSlide>
                            <SwiperSlide>
                                <a href='/kredit/elektronik'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Elektronik} alt='Elektronik' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Elektronik</strong>
                                </a>

                            </SwiperSlide>
                            <SwiperSlide>
                                <a href='/kredit/renovation'>
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={InteriorDesign} alt='Renovaiton' height={70} width={70} />
                                    <strong className='xs-m-10-t'>Renovation</strong>
                                </a>


                            </SwiperSlide>

                        </Swiper>
                    </div>



                    <Row style={{ height: '100%' }} className='xs-m-50-t sm-m-100-t xs-m-150-b desktop'>

                        <Column style={{ flex: 1.5 }}>
                            <Row>

                                <Column className="justify-right">
                                    <Card className='first-card' onClick={() => goTo('/kredit/business')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={Business} alt='business' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Business</strong>
                                    </Card>

                                </Column>

                                <Column>
                                    <Card onClick={() => goTo('/kredit/immobilien')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={Immobilien} alt='immobilien' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Immobilien</strong>
                                    </Card>
                                    <Card onClick={() => goTo('/kredit/reisen')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={Reisen} alt='Reisen' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Reisen</strong>
                                    </Card>
                                </Column>

                                <Column >

                                    <Card onClick={() => goTo('/kredit/bildung')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={Bildung} alt='Bildung' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Bildung</strong>
                                    </Card>
                                    <Card onClick={() => goTo('/kredit/fahrzeuge')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={Fahrzeuge} alt='Fahzeuge' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Fahzeuge</strong>
                                    </Card>

                                </Column>

                                <Column>
                                    <Card onClick={() => goTo('/kredit/elektronik')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={Elektronik} alt='Elektronik' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Elektronik</strong>
                                    </Card>
                                    <Card onClick={() => goTo('/kredit/renovation')}>
                                        <img className='card-plus' src={Plus} height={20} width={20} />
                                        <img src={InteriorDesign} alt='Renovation' height={70} width={70} />
                                        <strong className='xs-m-10-t'>Renovation</strong>
                                    </Card>
                                </Column>
                            </Row>

                        </Column>

                        <Column className='justify-center align-items-right'>

                            <Bubble />
                        </Column>
                    </Row>
                </Container>
            </div>
        </>
    )
}
