import { Container } from '../../common/theme/Container';
import { Row } from '../../common/theme/Row/';
import { Column } from '../../common/theme/Column/';
import { Input } from '../../common/theme/Input';

import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser'
import { LoadingOutlined } from '@ant-design/icons'


import './style.sass'


export const Contact = () => {
    const ref = useRef(null)
    const [applicationSent, setApplicationSent] = useState(false)
    const [isMessageSending, SetMessageSending] = useState(false)

    const handleFormSubmit = event => {
        event.preventDefault()

        SetMessageSending(true)
        setApplicationSent(true)
        emailjs.sendForm('service_op0lmv6', 'template_1hxvx0j', ref.current, 'gJ37SCMu1OSRRygMR')
            .then((result) => {
                console.log(result.text);

                SetMessageSending(false)
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <div id='contact' className='contact bg-white content-block'>

            <Container>
                <Row>
                    <Column className='justify-center'>
                        <h3>Benötigen Sie Hilfe bei <br /> einem Kredit?</h3>
                        <p className='xs-m-10-l xs-m-25-t xs-m-25-b font-dark-gray' style={{ width: '80%' }}>
                            Wir sind für Sie da, wenn Sie Fragen haben oder Hilfe benötigen.
                            Bitte zögern Sie nicht uns zu kontaktieren!
                        </p>
                    </Column>
                    <Column>
                        <form
                            className='bg-gray xs-p-25 sm-p-50 rounded-2 relative'
                            onSubmit={event => handleFormSubmit(event)}
                            ref={ref}
                        >
                            {
                                isMessageSending &&
                                <div className='static-row align-items-center justify-center full-width full-height'>
                                    <LoadingOutlined style={{ fontSize: '80px' }} />
                                </div>
                            }
                            {
                                !applicationSent && !isMessageSending &&
                                <>
                                    <Row>
                                        <Input type='text' name='contact-name' label='Name' required={true}/>
                                        <div style={{ width: '20px' }} />
                                        <Input type='text' name='contact-nachname' label='Nachname' required={true}/>
                                    </Row>
                                    <Row>
                                        <Input name='contact-telefonnummer' type='tel' label='Telefonnummer' required={true}/>
                                        <div style={{ width: '20px' }} />
                                        <Input name='contact-email' type='email' label='E-Mail' required={true}/>
                                    </Row>
                                    <Input type='textarea' required={true} name='contact-nachricht' label='Nachricht' height={100} />

                                    <button
                                        className='no-border bg-dark-gray font-white font-sm rounded-3'
                                        type='submit'
                                    >
                                        Absenden
                                    </button>
                                </>
                            }
                            {
                                applicationSent && !isMessageSending &&
                                <Column>
                                    <h4 className='font-md text-center'>Vielen Dank für Ihre Nachricht!</h4><br/>
                                    <p className='font-sm text-center'>Wir werden uns so schnell wie möglich bei Ihnen melden.</p>
                                </Column>
                            }

                        </form>
                    </Column>
                </Row>
                <div className='static-row text-center xs-m-100-t sm-m-200-t'>
                    <p>
                        Kreditdarstellung: Kredit von CHF 10'000. Effektive Jahreszinsen zwischen 4,5 % und 10,95 % über einen Zeitraum von 12 Monaten führen zu Gesamtzinsen zwischen CHF 318 und CHF 575.
                        Laufzeit: 6-120 Monate; Maximaler jährlicher Zinssatz (einschließlich aller Kreditbearbeitungskosten) 10,95 %. Kreditgenehmigungen sind verboten, wenn sie zu einer Überschuldung
                        des Verbrauchers führen. (Art. 3 LCD)
                    </p>
                </div>

            </Container>
        </div>
    )
}
