import { Container } from '../../common/theme/Container';
import { Row } from '../../common/theme/Row/';
import { Column } from '../../common/theme/Column/';
import { useParams } from 'react-router';
import Application from '../../common/img/application.svg';
import Contact from '../../common/img/contact.svg'
import Analysis from '../../common/img/analysis.svg';
import Offer from '../../common/img/offer.svg';
import Money from '../../common/img/moneybank.svg';
import Next from '../../common/img/next.svg';
import { motion } from 'framer-motion';

import Plus from '../../common/img/plus.svg'
import Immobilien from '../../common/img/immobilien.svg'
import Bildung from '../../common/img/bildung.svg'
import Elektronik from '../../common/img/elektronik.svg'
import Business from '../../common/img/business.svg'
import Reisen from '../../common/img/reisen.svg'
import Fahrzeuge from '../../common/img/fahrzeuge.svg'
import InteriorDesign from '../../common/img/interior.svg'

import ElektronikBackground from '../../common/img/webp/Elektronik.webp'
import ImmobilienBackground from '../../common/img/webp/Immobilien.webp'
import BildungBackground from '../../common/img/webp/Bildung.webp'
import ReisenBackground from '../../common/img/webp/Reisen.webp'
import FahrzeugeBackground from '../../common/img/webp/Fahrzeuge.webp'
import InteriorDesignBackground from '../../common/img/webp/InteriorDesign.webp'

import './style.sass'
import { Calculator } from '../Calculator';

export const LoanFor = () => {    
    const { id } = useParams()
    console.log(id)

    const textFor = {
        'business': 'Träumen Sie von Ihrer eigenen Selbstständigkeit oder haben Sie bereits den mutigen Schritt gewagt, Ihr eigenes Unternehmen zu gründen? Fast & Fair ist hier, um Ihre Träume zu verwirklichen! Lassen Sie uns gemeinsam die finanzielle Grundlage schaffen, die Ihnen die Sicherheit und Freiheit gibt, die Sie verdienen. Beginnen Sie jetzt Ihre Reise zu unternehmerischem Erfolg mit unseren individuellen Kapitallösungen!',
        'immobilien': 'Tauchen Sie ein in die Welt Ihres Traumhauses, sei es in der malerischen Schweiz oder an einem bezaubernden Ort im Ausland. Dank Fast & Fair sind ausreichend Eigenmittel für Ihr eigenes Stück Paradies bereit und warten darauf, Ihren Traum wahr werden zu lassen!',
        'reisen': 'Spüre den Puls der Abenteuerlust! Deine Reisepläne sind nicht nur Träume, sondern klopfen an die Tür der Realität. Egal, ob es ein spontaner Kurzurlaub ist oder eine Reise um die Welt – mit unserem blitzschnellen und fairen Kredit wird jede Auszeit zu einem unvergesslichen Abenteuer. Lass die Vorfreude beginnen und erfülle dir jetzt deine Reiseträume!',
        'bildung': 'Berufung gefunden? Fast & Fair ebnet den Weg für Ihren aufregenden Karrieresprung. Entfesseln Sie Ihr Potenzial mit einer Weiterbildung, finanziert durch unseren unterstützenden Kredit. Ihre Träume sind es wert!',
        'fahrzeuge' : 'Entdecken Sie die Freude am Fahren mit Fast & Fair! Egal, ob Sie von einem geräumigen Familien-Van träumen oder die Windböen in einem schicken Cabriolet spüren möchten – wir machen Ihre Auto-Träume wahr. Mit uns wird die Ablösung Ihres Leasings oder die Finanzierung Ihrer Neuwagenwünsche zu einem emotionalen Erlebnis. Erleben Sie die Leidenschaft fürs Autofahren – schnell, fair und unkompliziert!',
        'elektronik': 'Tauche ein in die Welt des ultimativen Klangerlebnisses – Stereoanlage oder Home-Cinema? Mit Fast & Fair erlebst du nicht nur rasche Kreditzusagen, sondern auch die Gewissheit, unvergessliche Momente der Emotion und Unterhaltung in den eigenen vier Wänden zu schaffen. Gönn dir das Beste für dein Zuhause und lass das Erlebnis beginnen!',
        'renovation': 'Brechen Sie aus dem Grau des Alltags aus und geben Sie Ihrem Zuhause eine neue Farbe! Mit Fast & Fair verwandeln Sie nicht nur langweilige Wände, sondern auch kalte Füße in warme Wohlfühlmomente. Erwecken Sie Ihre Renovierungsträume zum Leben und tauchen Sie ein in ein Zuhause voller Liebe und Gemütlichkeit. Starten Sie jetzt Ihre emotionale Reise zu einem perfekten Heim!',

    }

    return (
        <div id='loanFor' className='xs-p-100-t sm-p-300-t sm-p-300-b bg-gray relative'>
            <div className='loan-for-background' id={`${id}-background`}> 
                
            </div>
            <Container className='xs-p-10'>
                <Row>
                    <Column className='justify-center'>
                        <h2 className='font-xl font-green xs-m-25-b'>
                            {id.charAt(0).toUpperCase() + id.slice(1) + ' Kredit'}
                        </h2>
                        <p className='xs-m-25-b  sm-m-25-t sm-m-150-r font-white'>
                            {textFor[id]}
                        </p>
                    </Column>
                    <div>
                        <Column className='static-row justify-center xs-p-50-b'>
                            <div className='desktop'> 
                                <div className='card'>
                                    
                                    <img className='card-plus' src={Plus} height={20} width={20} />
                                    <img src={Business} alt='business' height={70} width={70} />
                                    <strong className='xs-m-10-t'>{id}</strong>
                                </div>
                            </div>                            
                            <div className='xs-m-50-t sm-m-0-t'>
                                <Calculator />
                            </div>
                            
                        </Column>
                        <div className='static-row justify-center'>
                            
                        </div>
                        
                    </div>
                </Row>

            </Container>
        </div>

    )
}
