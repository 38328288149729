import React from 'react'
import { Container } from '../../common/theme/Container'
import { Row } from '../../common/theme/Row'
import { Column } from '../../common/theme/Column'
import { DropDown } from '../../common/theme/DropDown'
import { useNavigate } from 'react-router'

import './style.sass'
import { Accordion } from '../../common/theme/Accordion'


export const FAQ = () => {
    const navigate = useNavigate()

    return (
        <div id='faq' className='bg-light-green xs-p-50-b sm-p-200-b'>
            <Container className='xs-p-10 sm-p-0'>
                <div className='static-row bg-white rounded-2 xs-p-25'>
                    <h3 className='font-lg bold-3 font-dark-gray'>FAQ</h3>
                    <h4 className='xs-m-25-l' style={{ opacity: .5 }} >Häufig gestellte Fragen</h4>
                </div>

                <Row className='faq-container relative xs-m-50-t'>
                    <Column className='faq-column faq-row-container'>
                        <Accordion
                            header='Welche Gründe sprechen für die Beantragung eines Kredits bei uns?'
                            className='bg-green rounded-2 xs-p-25 xs-m-10-t font-white font-sm'
                            contentClassName='font-dark-gray rounded-1 xs-p-25 bg-white xs-m-10'
                        >
                            <p>
                                Entscheiden Sie sich für einen Kreditpartner, der Ihre Bedürfnisse
                                wirklich versteht. Wir zeichnen uns durch unschlagbare
                                Geschwindigkeit in der Antragsbearbeitung aus. Bei uns erleben
                                Sie absolute Transparenz – sämtliche Details Ihres Kreditprozesses
                                werden klar und verständlich kommuniziert. Unser engagiertes
                                Team legt großen Wert auf persönliche Beratung, steht Ihnen
                                jederzeit zur Seite und geht flexibel auf Ihre individuellen
                                Anforderungen ein. Im Vergleich zu traditionellen Banken bieten
                                wir nicht nur Finanzierung, sondern eine maßgeschneiderte Lösung,
                                bei der Sie als geschätzter Kunde im Mittelpunkt stehen.
                            </p>
                        </Accordion>
                        <Accordion
                            header='Welche Personen sind berechtigt, einen Kredit zu beantragen?'
                            className='bg-green rounded-2 xs-p-25 xs-m-10-t font-white font-sm'
                            contentClassName='font-dark-gray rounded-1 xs-p-25 bg-white xs-m-10'
                        >
                            <p>
                                Jeder, der im Besitz des Schweizer Passes oder eines Ausländerausweises
                                der Kategorien B, C, L und G ist, kann einen Privatkredit beantragen.
                                Das Alter des Antragstellers sollte zwischen 18 und 70 Jahren liegen,
                                und unabhängig von der beruflichen Situation muss ein monatliches
                                Einkommen von mindestens CHF 2'300.- nachgewiesen werden.
                            </p>
                        </Accordion>

                        <Accordion
                            header='Welche Dokumente werden benötigt, um den Kreditantrag einzureichen?'
                            className='bg-green rounded-2 xs-p-25 xs-m-10-t font-white font-sm'
                            contentClassName='font-dark-gray rounded-1 xs-p-25 bg-white xs-m-10'
                        >
                            <strong>Um Ihren Antrag zu bearbeiten, benötigen wir die folgenden Unterlagen:</strong><br /><br />
                            <ul style={{ listStyleType: 'decimal' }}>
                                <li>Die letzten drei Lohnabrechnungen beider Ehegatten/Partner.</li>
                                <li>Eine Kopie Ihres gültigen Personalausweises oder Reisepasses.</li>
                                <li>Falls Sie Ausländer sind, benötigen wir zusätzlich eine Kopie Ihres Ausländerausweises.</li>
                            </ul><br />
                            <p>
                                Es kann sein, dass wir Sie kontaktieren, um weitere Nachweise anzufordern, wie z.B.
                                Unterlagen zu Ihrer Wohnsituation, Krankenversicherung, beruflichen Situation und
                                finanziellen Lage, einschließlich laufender Kredite.
                            </p>
                        </Accordion>

                    </Column>
                    <Column className='faq-column faq-row-container'>
                        <Accordion
                            header='Welche Anforderungen sind notwendig, um einen Kredit zu erhalten?'
                            className='bg-green rounded-2 xs-p-25 xs-m-10-t font-white font-sm'
                            contentClassName='font-dark-gray rounded-1 xs-p-25 bg-white xs-m-10'
                        >
                            <strong>Unsere Kredite setzen folgende Bedingungen voraus:</strong><br /><br />
                            <ul>
                                <li>Volljährigkeit</li>
                                <li>Unselbständige oder selbständige Erwerbstätigkeit seit über 12 Monaten</li>
                                <li>Keine Verlustscheine</li>
                                <li>Wohnsitz in der Schweiz oder Liechtenstein bzw. Grenzgängerstatus</li>
                            </ul> <br />
                            <p>
                                Zusätzlich hängt die Kreditvergabe grundsätzlich von Ihrer Bonität ab.
                                Jeder Kreditantrag wird individuell von uns geprüft.
                            </p>
                        </Accordion>
                        <Accordion
                            header='Sind bei der Kreditbeantragung Gebühren zu entrichten?'
                            className='bg-green rounded-2 xs-p-25 xs-m-10-t font-white font-sm'
                            contentClassName='font-dark-gray rounded-1 xs-p-25 bg-white xs-m-10'
                        >
                            <p>Es entstehen keinerlei Bearbeitungs- oder Kontoführungsgebühren!</p>
                        </Accordion>


                        <Accordion
                            header='Ich habe bereits einen Kredit. Ist es möglich, einen weiteren Kredit zu beantragen oder meinen bestehenden Kredit aufzustocken?'
                            className='bg-green rounded-2 xs-p-25 xs-m-10-t font-white font-sm'
                            contentClassName='font-dark-gray rounded-1 xs-p-25 bg-white xs-m-10'
                        >
                            <p>
                                In der Regel stellt dies kein Problem dar, sofern es Ihr
                                Budget zulässt. Sie haben die Möglichkeit, Ihren bestehenden
                                Kredit durch günstigere Zinsen abzulösen oder aufzustocken.
                                Wir beraten Sie gerne über die verschiedenen Möglichkeiten.
                                Seit Sommer 2016 gelten neue Zinssätze, weshalb eine Umschuldung
                                auf einen niedrigeren Zinssatz für viele Kreditnehmer sinnvoll sein
                                kann.
                            </p>
                        </Accordion>

                    </Column>
                </Row>
                <div className='static-row justify-center xs-p-50-t sm-p-100-t'>
                    <button className='font-sm font-white bg-dark-gray no-border                    
                    rounded-3 bold-2 show-more-button'
                        onClick={async () => {
                            await navigate('/faq')
                            document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
                        }}
                    >

                        Zeige mehr
                    </button>
                </div>


            </Container>
        </div>
    )
}
