import { Column } from '../../common/theme/Column/';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { updateData } from '../../Actions/dataActions';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from '../../common/theme/Row';


import './style.sass'

export const Calculator = () => {
    const [loanAmount, setLoanAmount] = useState(useSelector(state => state.data.loanAmount))    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const calculateMonthlyPayment = rate => {
        rate = rate / 100 / 12
        let term = 120 / 12 * 12

        let payment = loanAmount.toString().replace("'", '') / (((Math.pow(1 + rate, term)) - 1) / (rate * (Math.pow(1 + rate, term))))

        return payment.toFixed(2)
    }

    const handleAmountOnBlur = (event) => {
        let amount = event.target.value.toString().replace(`'`, '').replace(`'`, '')
        if (parseInt(amount) < 5000 || !parseInt(amount)) {
          setLoanAmount(5000)
          dispatch(updateData(5000)) 
        }
        else if (parseInt(amount) > 300000) {
          setLoanAmount(300000)  
          dispatch(updateData(300000))    
        } else {
            dispatch(updateData({loanAmount}))
        }                 
    }

    const handleAmountFocus = event => {
        setLoanAmount('')        
    }


	return (
		<motion.div 
            className='calculator full-width bg-white rounded-2 xs-p-25 xs-m-50-b sm-m-0-b'
            whileHover={{scale:[null, 1.03]}}            
        >  
            <Row className='relative justify-left align-items-left'>
                <hr/>
                
                    <Column className='xs-m-0-r sm-m-25-r relative input-container'>
                        <h3 className='font-xs bold-2 font-sm'>Gewünschte Summe:</h3>
                        <input 
                            type={'text'} 
                            value={loanAmount.toString().replace("'",'').replace(/\B(?=(\d{3})+(?!\d))/g, "'")}
                            onFocus={handleAmountFocus}
                            className='xs-m-10-t text-center font-sm bold-2'      
                            onChange={event => {
                                if (event.target.value.length < 9)
                                    setLoanAmount(event.target.value)
                            }}         
                            inputMode='numeric' // Ensures numeric keyboard on mobile              
                            onBlur={handleAmountOnBlur}                                               
                        />
                        <span id='chf-input'>CHF</span>
                    </Column>
                    <Column className=' xs-m-0-l  sm-m-0-t sm-m-25-l input-container'>
                        <h3 className=' xs-m-25-t sm-m-0-t font-xs bold-2'>Beispiel für eine monatliche <br className='mobile'/> Zahlung:</h3>  
                        <strong className='xs-m-10-t'>CHF <span className='font-sm bold-4'>{calculateMonthlyPayment(4.4)}</span></strong>                  
                    </Column>                
                
            </Row>  
            <div className='static-row justify-center'>
                <button
                    onClick={() => {
                        navigate('/kreditantrag')
                    }}
                    className='antrag-button rounded-3 font-sm bg-dark-gray bold-2'>Kreditantrag stellen</button>    
            </div>
		</motion.div>
	)
}
