import { Header } from "./components/Header";
import { LoanList } from "./components/LoanList";
import { About } from "./components/About"
import { Roadmap } from "./components/Roadmap";
import { FAQ } from "./components/FAQ";
import { Contact } from "./components/Contact/Contact";
import { Footer } from "./components/Footer";
import { Navigation } from "./components/Navigation";
import { Route, Routes } from "react-router";
import { Application } from "./components/Application";
import { ParticlesComponent } from "./components/LoanList/ParticlesComponent";
import { AnimatePresence } from "framer-motion";
import { FaqPage } from "./components/FAQ/FaqPage";
import { LoanFor } from "./components/LoanFor";
import { Datenschutz } from "./components/Datenschutz";
import { Impressum } from "./components/Impressum";

function App() {
  const pathName = window.location.pathname;

  const homeRoutes = [    
    '/business-kredit',   
    '/immobilien-kredit',
    '/fahrzeuge-kredit',
    '/reisen-kredit',
    '/renovation-kredit',
    '/elektronik-kredit',
    '/bildung-kredit',
  ]

  const home = (
    <>                          
      
      <LoanList />
      <About />
      <Roadmap />
      <FAQ />
      <Contact />
      
      
    </>
  )


  return (
    <AnimatePresence mode="wait">
      <div className="App">
        <Navigation />
    
        <Routes>
          <Route exact path ='/kreditantrag' element={ <Application /> } />      
          <Route exact path ='/faq' element={ <FaqPage /> } />
          <Route exact path ='/datenschutz' element={ <Datenschutz /> } />
          <Route exact path ='/impressum' element={ <Impressum /> } />
          <Route path ='/' element={<> <Header /> { home } </> } /> 
          <Route path ='/kredit/:id' element={ (<> < LoanFor /> { home }  </> ) } />'         
          
        </Routes>
        <div className="desktop" style={{height:'600px'}} />  
        
        <Footer />          
        
      </div>
    </AnimatePresence>
    
  );
}

export default App;
