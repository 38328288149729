import { Container } from '../../common/theme/Container';
import { Row } from '../../common/theme/Row/';
import { Column } from '../../common/theme/Column/';
import Application from '../../common/img/application.svg';
import Contact from '../../common/img/contact.svg'
import Analysis from '../../common/img/analysis.svg';
import Offer from '../../common/img/offer.svg';
import Money from '../../common/img/moneybank.svg';
import Next from '../../common/img/next.svg';
import { useNavigate } from 'react-router';
import { motion } from 'framer-motion';

import './style.sass'

export const Roadmap = () => {
    const navigate = useNavigate()
    return (
        <div className='roadmap content-block bg-light-green'>
            <Container>
                <h3 className='font-dark-gray'><b>Ihr Antrag</b> & nächste Schritte:</h3>
                <p className='xs-m-50-t font-sm'>
                Effektive Bewerbungsvorbereitung ist entscheidend. Unsere professionelle Beratung wird Sie durch den Prozess stets begleiten. Fast & Fair hat klare Schritte für maximale Erfolgschancen definiert.
                </p>
                <Row className="xs-p-50-t steps">

                    <motion.div
                        whileHover={{ scale: [null, 1.1] }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}

                        className='bg-white xs-p-25 rounded-3'
                    >
                        <div className='static-row align-items-left full-height roadmap-container'>
                            <Column className="align-items-center sequence-step">
                                <div className='static-row'>
                                    <img src={Application} alt='Kreditanfrage' />
                                    <div className='step-number static-row justify-center bg-green xs-p-10 font-white font-sm bold-3'>
                                        1
                                    </div>
                                </div>

                                <p>
                                    Ausfüllen des Antrags
                                </p>
                            </Column>


                            <Column className='full-height justify-center arrow-next'><img src={Next} alt='' /></Column>
                            <Column className="align-items-center sequence-step">
                                <div className='static-row'>
                                    <img src={Contact} alt='Kontakt' />
                                    <div className='step-number  static-row justify-center bg-green xs-p-10 font-white font-sm bold-3'>
                                        2
                                    </div>
                                </div>
                                
                                <p>
                                    Wir kontaktieren Sie telefonisch. Am selben Tag
                                </p>
                            </Column>
                            <Column className='full-height justify-center arrow-next'><img src={Next} alt='' /></Column>
                            <Column className="align-items-center sequence-step">
                                <div className='static-row'>
                                    <img src={Analysis} alt='Analyse' />
                                    <div className='step-number  static-row justify-center bg-green xs-p-10 font-white font-sm bold-3'>
                                        3
                                    </div>
                                </div>
                                
                                <p>
                                    Analyse Ihrer Kreditanfrage
                                </p>
                            </Column>
                            <Column className='full-height justify-center arrow-next'><img src={Next} alt='' /></Column>
                            <Column className="align-items-center sequence-step">
                                <div className='static-row'>
                                    <img src={Offer} alt='Angebot' />
                                    <div className='step-number  static-row justify-center bg-green xs-p-10 font-white font-sm bold-3'>
                                        4
                                    </div>
                                </div>
                                
                                <p>
                                    Es wird ein maßgeschneidertes Angebot verschickt
                                </p>
                            </Column>
                            <Column className='full-height justify-center arrow-next'><img src={Next} alt='' /></Column>
                            <Column className="align-items-center sequence-step">
                                <div className='static-row'>
                                    <img src={Money} alt='Geld' />
                                    <div className='step-number  static-row justify-center bg-green xs-p-10 font-white font-sm bold-3'>
                                        5
                                    </div>
                                </div>
                               
                                <p>
                                    Das Geld wird auf Ihr Konto überwiesen
                                </p>
                            </Column>
                        </div>
                    </motion.div>

                </Row>

                <div className='static-row justify-center xs-m-50-t'>
                    <button className='xs-m-25-t font-sm font-white bg-dark-gray no-border
                    rounded-3 bold-2'
                    onClick={() => {
                        navigate('/kreditantrag')
                        document.body.scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}
                >
                    Kredit Jetzt beantragen</button>
                </div>



            </Container>
        </div>

    )
}
