import { useState } from "react"
import { useDispatch } from "react-redux"
import { updateData } from "../../../../Actions/dataActions"
import { Row } from "../../Row"

export const Options = props => {
    const [checked, setIsChecked] = useState(null)
    const dispatch = useDispatch()

    const handleOptionClick = id => {
        setIsChecked(id)
        console.log(props.label)
        console.log(props.options.filter(option => option.id == id).pop().value)
        dispatch(updateData({[props.name]:props.options.filter(option => option.id == id).pop().value}))
    }


    return (
        <div className=" xs-m-50-t full-width">
            <label>{props.label}</label>
            {

                props.options.map(option => {
                    if (props.options.indexOf(option) == 0 || props.options.indexOf(option) == 2) {                        
                        const secondOption = props.options[props.options.indexOf(option) + 1]

                        return (

                            <Row className="xs-m-0-t sm-m-10-t">
                                <div 
                                    id={option.id}
                                    className={`data-option rounded-2 xs-p-25 sm-m-10-r xs-m-10-t bold-1 font-sm ${checked == option.id ? 'pointer bg-light-green' : 'pointer bg-gray'}`}
                                    onClick={() => handleOptionClick(option.id)}
                                >
                                    {option.value}

                                </div>

                                {
                                    <div
                                        id={secondOption?.id}
                                        className={`rounded-2 xs-p-25 sm-m-10-l bold-1 data-option xs-m-10-t font-sm ${secondOption.value && (checked == secondOption?.id ? 'pointer bg-light-green' : 'pointer bg-gray')}`}
                                        onClick={() => secondOption.value && handleOptionClick(secondOption?.id)}
                                    >
                                        {secondOption?.value}
                                    </div>
                                }
                            </Row>
                        )
                    }
                })
            }

        </div>


    )
}