import { useMotionValue, useTransform, motion} from "framer-motion"
import ReactFlipCard from 'reactjs-flip-card'

export const Card = props => {
    const styles = {
        card: {background: 'green', color: 'white', borderRadius: 20,},
    }

	return (
		<motion.div 
            style={{position: 'relative', zIndex: 100, minWidth: '200px'}}
            whileHover={{scale: [null, 1.05, 1.05], position: 'relative', zIndex: 10000}}
            initial={{ opacity: 0, translateY: '50%' }}
            whileInView={{ opacity: 1, translateY: '0'}}
            viewport={{ once: true }}
            transition={{ duration: .5}}
            className="pointer"
            onClick={props.onClick}           
        > 
            <ReactFlipCard
                frontComponent={props.children}
                backComponent={<div className="">Zeig mehr</div>}
                containerCss={`bold-3 loan-card-container font- relative ${props.className}`}
                flipCardCss='bg-green loan-card'
                //backStyle={{boxShadow: '0px 0px 20px black'}}
                backCss="loan-card-back"
                direction="vertical"
            >
                

            </ReactFlipCard>
            
		</motion.div>
	)
}
