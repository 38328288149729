import { Container } from '../../common/theme/Container';
import { Row } from '../../common/theme/Row/';
import { Column } from '../../common/theme/Column/';
import { Calculator } from '../Calculator';
import { TypeAnimation } from 'react-type-animation';
import Black from '../../common/img/black.png'
import { motion } from 'framer-motion';

import Checkbox from '../../common/img/checkbox.svg'

import './style.sass'

export const Header = () => {
	return (
		<div id='header' className='relative'>  
			<div className='header-separator desktop' />
			<button id='down-button'>&#8249;</button>
			<Container className="full-height xs-p-10 xs-p-100-t sm-p-200-t ">				
				<Row>
					<Column className="justify-center font-white">
						<h1 className='sm-m-0-l xs-font-xl bold-4' style={{width: '400px', minHeight: '100px', lineHeight:'65px'}}>
							<TypeAnimation 
								sequence={[
									'Ihr Vertrauenspartner im Kreditgeschäft',
        							1000,
								]}	
								wrapper='span'
								speed={10}
								repeat={Infinity}	
								cursor={true}								
							/>
							
						</h1>
						

						<Column className='checkboxes desktop'>
							
							<div className='static-row bold-3 font-sm'><img height={30} className='xs-m-10-r' src={Checkbox} alt='checkbox' />Ab 4.9% Zinsen</div>
							<div className='static-row bold-3 xs-m-10-t font-sm'><img height={30} className='xs-m-10-r' src={Checkbox} alt='checkbox' />Analyse mit Experten</div>
							<div className='static-row bold-3 xs-m-10-t font-sm'><img height={30} className='xs-m-10-r' src={Checkbox} alt='checkbox' />Kostenfrei</div>
						</Column>
					</Column>
					<Column className="justify-right align-items-center">
						<motion.img
							initial={{scale:1.8}}
							animate={{
								scale: [1.8, 2],								
							}}
							transition={{ease:'easeOut', duration: 15}}						
						src={Black} className='header-image desktop' alt='Smartphone' />

						<div className='calculator-container xs-m-50-t sm-m-0-t xs-m-50-b sm-m-0-b'>
							<Calculator />
						</div>
                        
						
                    </Column>

					<Column className='mobile xs-m-50-b'>							
							<div className='rounded-3 xs-p-10 static-row bold-3 font-sm'><img height={30} className='xs-m-10-r' src={Checkbox} alt='checkbox' />Ab 4.9% Zinsen</div>
							<div className=' xs-m-10-t rounded-3 xs-p-10 static-row bold-3 font-sm'><img height={30} className='xs-m-10-r' src={Checkbox} alt='checkbox' />Analyse mit Experten</div>
							<div className='xs-m-10-t rounded-3 xs-p-10 static-row bold-3 font-sm'><img height={30} className='xs-m-10-r' src={Checkbox} alt='checkbox' />Kostenfrei</div>
						</Column>
				</Row>
				
				
			</Container>
		</div>
	)
}
