const initialState = {
    loanAmount: 20000,
    loanTerm: 72,
    loanOption: 0,
    ratenabsicherung: false,
    dataValid: true,
    confirmation1: false, 
    confirmation2: false,
}

export const dataReducer = (state = initialState, action) => {
    switch(action.type) {
        case 'UPDATE_DATA':
            if (Object.keys(action.payload).length === 0) {                
                return initialState
            }
                
            else 
                return { ...state, ...action.payload }
        case 'SET_DATA_VALID':
            return {...state, ...{dataValid: action.payload}}
        default: 
            return state
    }
}