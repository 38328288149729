import { Accordion } from "../../../common/theme/Accordion"
import { Container } from "../../../common/theme/Container"

import './style.sass'


export const FaqPage = () => {
    return (
        <div id='faqPage' className="xs-p-25-t sm-p-0-t">
            <Container className='content-block'>
                <h3>Häufig gestellte Fragen (FAQ)</h3>
                <p className="xs-m-10-t sm-m-50-b">Hier finden Sie eine Übersicht der am häufigsten gestellten Fragen zum Fast & Fair Credit Kreditantrag.</p>

                <Accordion
                    header='Welche Gründe sprechen für die Beantragung eines Kredits bei uns?'
                    className='xs-m-25-b xs-m-50-t font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Entscheiden Sie sich für einen Kreditpartner, der Ihre Bedürfnisse
                        wirklich versteht. Wir zeichnen uns durch unschlagbare
                        Geschwindigkeit in der Antragsbearbeitung aus. Bei uns erleben
                        Sie absolute Transparenz – sämtliche Details Ihres Kreditprozesses
                        werden klar und verständlich kommuniziert. Unser engagiertes
                        Team legt großen Wert auf persönliche Beratung, steht Ihnen
                        jederzeit zur Seite und geht flexibel auf Ihre individuellen
                        Anforderungen ein. Im Vergleich zu traditionellen Banken bieten
                        wir nicht nur Finanzierung, sondern eine maßgeschneiderte Lösung,
                        bei der Sie als geschätzter Kunde im Mittelpunkt stehen.
                    </p>
                </Accordion>

                <Accordion
                    header='Welche Personen sind berechtigt, einen Kredit zu beantragen?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Jeder, der im Besitz des Schweizer Passes oder eines Ausländerausweises
                        der Kategorien B, C, L und G ist, kann einen Privatkredit beantragen.
                        Das Alter des Antragstellers sollte zwischen 18 und 70 Jahren liegen,
                        und unabhängig von der beruflichen Situation muss ein monatliches
                        Einkommen von mindestens CHF 2'300.- nachgewiesen werden.
                    </p>
                </Accordion>

                <Accordion
                    header='Welche Anforderungen sind notwendig, um einen Kredit zu erhalten?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <strong>Unsere Kredite setzen folgende Bedingungen voraus:</strong><br /><br />
                    <ul>
                        <li>Volljährigkeit</li>
                        <li>Unselbständige oder selbständige Erwerbstätigkeit seit über 12 Monaten</li>
                        <li>Keine Verlustscheine</li>
                        <li>Wohnsitz in der Schweiz oder Liechtenstein bzw. Grenzgängerstatus</li>
                    </ul> <br />
                    <p>
                        Zusätzlich hängt die Kreditvergabe grundsätzlich von Ihrer Bonität ab.
                        Jeder Kreditantrag wird individuell von uns geprüft.
                    </p>
                </Accordion>

                <Accordion
                    header='Sind bei der Kreditbeantragung Gebühren zu entrichten?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>Es entstehen keinerlei Bearbeitungs- oder Kontoführungsgebühren!</p>
                </Accordion>
                <Accordion
                    header='Welche Aufwendungen entstehen durch den Bearbeitungsprozess?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>Die Anfrage und Bearbeitung des Kredits sind bei uns selbstverständlich kostenfrei.</p>
                </Accordion>

                <Accordion
                    header='Wie kann ich meine Kreditwürdigkeit verbessern?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Die Verbesserung der Kreditwürdigkeit erfordert pünktliche Zahlungen, die 
                        Reduzierung von Schulden und die Vermeidung unnötiger Kreditanfragen. 
                        Langfristige finanzielle Verantwortung trägt dazu bei, den Kredit-Score 
                        zu erhöhen.
                    </p>
                </Accordion>



                <Accordion
                    header='Um was genau geht es beim Scoring-System?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Ihre Bonität spielt eine entscheidende Rolle bei der Beurteilung Ihrer
                        Kreditwürdigkeit, da sie Ihre allgemeine finanzielle Zuverlässigkeit
                        widerspiegelt. Die Bank überprüft Ihr Zahlungsverhalten in Bezug auf
                        Kredite, Fahrzeugleasing und Kreditkarten, indem sie verschiedene
                        Datenbanken analysiert. Zudem fließen frühere Kreditablehnungen, die im
                        Kreditcenter erfasst sind, in die Bewertung mit ein. Daher ist es ratsam,
                        sich direkt bei der Bank zu bewerben, die am besten zu Ihren Bedürfnissen
                        passt. Dies erhöht Ihre Chancen auf eine positive Kreditentscheidung
                        und ermöglicht es Ihnen, im Laufe der Zeit Ihre Bonität zu verbessern.
                    </p>
                </Accordion>
                <Accordion
                    header='Wie ist die Rückzahlung des Kredits strukturiert?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Der Privatkredit wird in monatlich gleichbleibenden Raten zurückgezahlt,
                        wobei die letzte Rate gegebenenfalls abweichen kann. Sondertilgungen
                        sind ebenfalls möglich, falls Sie dies wünschen. Die erste Rate ist
                        einen Monat nach der Auszahlung fällig. Diese Form des Kredits wird
                        auch als Ratenkredit bezeichnet.
                    </p>
                </Accordion>
                <Accordion
                    header='Welche Zinsen bietet Fast & Fair Credit an?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Fast & Fair Credit bietet Zinssätze von 4.4% bis 10.95% an. Der niedrigste
                        Zinssatz wird gewährt, wenn der Kreditnehmer Schweizer Staatsbürger ist
                        oder über einen B- oder C-Ausweis verfügt, eine Festanstellung nachweisen
                        kann und eine gute Bonität aufweist (keine Betreibungen in den letzten 3
                        Jahren). Unser Ziel ist es, stets das kostengünstigste Angebot für
                        Sie zu finden.
                    </p>
                </Accordion>

                <Accordion
                    header='Kann ich vorzeitig meinen Kredit zurückzahlen?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Ja, viele Kredite ermöglichen eine vorzeitige Rückzahlung. Es ist jedoch 
                        wichtig, die Bedingungen und mögliche Gebühren im Voraus zu prüfen.
                    </p>
                </Accordion>


                <Accordion
                    header='Welche Dokumente werden benötigt, um den Kreditantrag einzureichen?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <strong>Um Ihren Antrag zu bearbeiten, benötigen wir die folgenden Unterlagen:</strong><br /><br />
                    <ul style={{listStyleType:'decimal'}}>
                        <li>Die letzten drei Lohnabrechnungen beider Ehegatten/Partner.</li>
                        <li>Eine Kopie Ihres gültigen Personalausweises oder Reisepasses.</li>
                        <li>Falls Sie Ausländer sind, benötigen wir zusätzlich eine Kopie Ihres Ausländerausweises.</li>
                    </ul><br />
                    <p>
                        Es kann sein, dass wir Sie kontaktieren, um weitere Nachweise anzufordern, wie z.B. 
                        Unterlagen zu Ihrer Wohnsituation, Krankenversicherung, beruflichen Situation und 
                        finanziellen Lage, einschließlich laufender Kredite.
                    </p>
                </Accordion>
                <Accordion
                    header='Welche Laufzeiten und Ratenhöhen stehen bei den Kreditangeboten zur Auswahl?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Die Laufzeit Ihres Kredits kann zwischen 12 und 120 Monaten variieren, 
                        was Ihnen eine flexible und individuelle Auswahl der Ratenhöhe ermöglicht.
                    </p>
                </Accordion>
                <Accordion
                    header='Wer trifft die Entscheidung über den Kreditabschluss und die maximale Kredithöhe?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Der Abschluss und die maximale Kredithöhe werden von der jeweiligen Kreditbank festgelegt, die den Kredit anbietet.
                    </p>
                </Accordion>
                <Accordion
                    header='Was ist ein ZEK-Code?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        ZEK bedeutet "Zentralstelle für Kreditinformationen". 
                        Dort wird registriert, wenn Sie einen Kredit nicht regelmässig 
                        und ordnungsgemäss abbezahlt haben. Wenn Sie einen negativen 
                        ZEK-Code besitzen, wird Ihnen die Bank keinen Kredit gewähren.
                    </p>
                </Accordion>
                <Accordion
                    header='Ich habe bereits einen Kredit. Ist es möglich, einen weiteren Kredit zu beantragen oder meinen bestehenden Kredit aufzustocken?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        In der Regel stellt dies kein Problem dar, sofern es Ihr 
                        Budget zulässt. Sie haben die Möglichkeit, Ihren bestehenden 
                        Kredit durch günstigere Zinsen abzulösen oder aufzustocken.  
                        Wir beraten Sie gerne über die verschiedenen Möglichkeiten. 
                        Seit Sommer 2016 gelten neue Zinssätze, weshalb eine Umschuldung 
                        auf einen niedrigeren Zinssatz für viele Kreditnehmer sinnvoll sein 
                        kann.
                    </p>
                </Accordion>
                <Accordion
                    header='Was ist eine Echtheitsbestätigung und wo erhalte ich diese?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Dabei handelt es sich um die «gelbe Identifikation«, die Sie bei Ihrer 
                        nächsten Postfiliale  erhalten.
                    </p>
                </Accordion>
                <Accordion
                    header='Was ist eine Kreditversicherung?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>                    
                        Eine Kreditversicherung schützt Kreditnehmer vor unvorhergesehenen 
                        Ereignissen wie Arbeitslosigkeit oder Krankheit, die die Rückzahlung 
                        des Kredits erschweren könnten.
                    </p>
                </Accordion>
                <Accordion
                    header='Wann erhalte ich das Geld?'
                    className='xs-m-25-b font-sm accordion'
                    contentClassName='xs-p-25-b'
                >
                    <p>
                        Die Auszahlung erfolgt nach Ablauf der gesetzlichen Widerrufsfrist von 14 
                        Tagen, basierend auf dem Vertragsdatum. Bei Verträgen über 80'001 CHF entfällt 
                        die Wartefrist, und das Geld wird direkt nach Prüfung des Kreditvertrags 
                        ausgezahlt.
                    </p>
                    
                </Accordion>
                

            </Container>
        </div>
    )
}
