import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { updateData } from '../../../../Actions/dataActions'

import './style.sass'

export const Radio = props => {
    const [checked, setIsChecked] = useState(props.initial)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(updateData({loanOption: checked}))
    }, [checked])

    return (
        <>
        {
            props.options.map(option => {                
                return (
                    <div className='static-row align-items-center xs-m-10-b'>            
                        <div
                            id={option.id}
                            className="radio-container static-row justify-center align-items-center"
                            data-ison={checked == option.id}
                            onClick={() =>{     
                                setIsChecked(option.id)                                                     
                            }}
                        />                                
                        <p className='full-height xs-m-10-l bold-3'>{option.name}</p>
                    </div>
                )
            })
        }
        </>

    )
}