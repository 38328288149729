import { MotionConfig, motion } from 'framer-motion'
import Cash from '../../common/img/cash.svg'



import './style.sass'
import { useEffect, useRef, useState } from 'react'


export const Bubble = () => {

    const getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window
        return {
            width,
            height
        }
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    
    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <>
        
            <motion.div 
                    style={{opacity:.7}}
                    
                    drag
                    dragConstraints={{
                        top: -200,
                        left: -windowDimensions.width / 1.7,
                        right: windowDimensions.width / 7,
                        bottom: 200,
                    }}         
                    initial={{ opacity: 0, scale: [null, 0, 0]}}
                    whileInView={{ opacity: .7, scale: [null, 1, 1]}}
                    viewport={{ once: false }}                    
                    transition={{duration: 10, stiffness: 400, damping: 10 }}                   
                    dragTransition={{ bounceStiffness: 50, bounceDamping: 5, bounce: 1, mass: 1 }}
                    dragElastic={0.5}
                    whileTap={{ cursor: "grabbing",}}
                    whileHover={{scale: [null, 1.2, 1.2], rotate: '45deg', opacity: .85}}
                    
                >                    
                    <img src={Cash} className='bubble' width={10} height={10}/>
                    
                </motion.div>        
            
        </>
        
            
        
    ) 
        
}