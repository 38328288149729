import { useState } from "react"
import { Column } from "../../common/theme/Column"
import { Container } from "../../common/theme/Container"
import { Row } from "../../common/theme/Row"

import Facebook from '../../common/img/facebook.svg'
import Instagram from '../../common/img/insta.svg'

import Logo from '../../common/img/ff_logo.svg'
import { useNavigate } from "react-router"
import Phone from '../../common/img/contact-telefon.svg'

import './style.sass'

export const Footer = (props) => {
    const navigate = useNavigate()
    const scrollTo = (elementId, position) => {
        if (elementId)
            document.getElementById(elementId).scrollIntoView({ behavior: 'smooth', block: position, inline: 'nearest' })
        else
            document.body.scrollIntoView({ behavior: 'smooth', blcok: 'position', inline: 'nearest' })
    }
    return (
        <footer className="xs-p-25-t sm-p-50-t">
            <Container className='xs-p-10 sm-p-0'>
                <div className="xs-m-50-b sm-m-100-b static-row footer-logo">
                    <img className="xs-m-25-r" src={Logo} alt="Fast & Fair Credit Logo" height={70} />
                    <hr className="xs-m-25-r" />
                    <h2 className="font-lg">Fast & Fair <span style={{ color: 'white' }}>Credit</span></h2>
                </div>

                <Row className='font-sm'>
                    <Column>
                        <a href='/datenschutz' className="sm-m-25-t">Datenschutz</a>
                        <a href='/impressum' className="sm-m-25-t xs-m-10-t">Impressum</a>
                        <a href='/faq' className="sm-m-25-t xs-m-10-t">FAQ</a>
                    </Column>

                    <Column>
                        <a onClick={async () => {
                            await navigate('/')
                            scrollTo('about', 'center')
                        }} className="sm-m-25-t xs-m-10-t">Über Uns</a>
                        <a className="sm-m-25-t xs-m-10-t" onClick={async () => {
                            await navigate('/')


                            scrollTo('', 'start')
                        }}>Home</a>
                        <a onClick={async () => {
                            await navigate('/')
                            scrollTo('contact', 'center')
                        }} className="sm-m-25-t xs-m-10-t">Kontakt</a>
                    </Column>

                    <Column>
                        <p className="xs-m-25-t bold-3">Folgen Sie uns:</p>
                        <div className="static-row xs-m-25-t">
                            <a href='https://www.facebook.com/profile.php?id=61554400786758'><img className="xs-m-25-r" src={Facebook} alt="Facebook Logo" height={30} /></a>
                            <a href='https://www.instagram.com/fastfaircredit/'><img src={Instagram} alt="Instagram Logo" height={30} /></a>
                        </div>
                    </Column>
                </Row>
                <hr className="xs-m-100-t" style={{ opacity: .3 }} />
                <Row className='xs-m-10-t bold-3 font-sm'>
                    <p className="static-row justify-center"><img className="xs-m-10-r" height={'20px'} src={Phone} alt='Telefon'/>+41 76 701 38 45</p>
                </Row>
                <div className="static-row justify-center xs-m-50-t xs-m-50-b">
                    <span>© Fast & Fair Credit 2023</span>
                </div>
            </Container>
        </footer>
    )
}
