import { useState, useEffect } from "react"
import { useDispatch } from "react-redux";
import { updateData } from "../../../../Actions/dataActions";
import { motion } from "framer-motion"

import "./style.sass";

export const Slider = props => {
    const dispatch = useDispatch()

    const [isOn, setIsOn] = useState(props.initial)

    const toggleSwitch = () => setIsOn(!isOn)

    useEffect(() => {
        dispatch(updateData({[props.name]: isOn}))
    }, [isOn])

    const spring = {
        type: "spring",
        stiffness: 700,
        damping: 30
      };
      

    return (
        <div className="switch" name={props.name} data-isOn={isOn} onClick={toggleSwitch}>
            <motion.div className="handle" layout transition={spring} />
        </div>
    )
}